import React from 'react';
import {
	Container,
	FormWrapper,
	RadioWrapper,
	RowWrapper,
	TextWrapper,
	WarnWrapper,
} from './style';
import {Radio, Text} from '../../Atom';
import {TextForm} from '../index';

const RadioForm = ({
	text,
	englishText,
	value,
	required,
	category = 'default',
	onChange,
	items = [],
	name,
	warnText,
	multiple,
	isDisabled,
}) => {
	const textProps = {
		text,
		englishText,
		category,
	};

	return (
		<Container>
			<RowWrapper>
				<TextWrapper>
					<TextForm {...textProps} />
				</TextWrapper>
				<FormWrapper isLong={items.length >= 3}>
					{items.map((item, index, array) => (
						<RadioWrapper key={index}>
							<Radio
								isDisabled={isDisabled}
								name={name}
								value={item.value}
								onChange={onChange}
								checked={
									multiple
										? value.findIndex(
												(e) => e === item.value,
										  ) >= 0
										: item.value === value
								}
							>
								{item.children}
							</Radio>
						</RadioWrapper>
					))}
				</FormWrapper>
			</RowWrapper>

			<RowWrapper>
				<TextWrapper></TextWrapper>
				<WarnWrapper>
					{warnText && (
						<Text {...warnText}>
							{isDisabled && (
								<Text {...warnText}>
									대회 코스는 수정할 수 없습니다. 사무국으로
									문의주시길 바랍니다.
								</Text>
							)}
						</Text>
					)}
				</WarnWrapper>
			</RowWrapper>
		</Container>
	);
};

export default RadioForm;
