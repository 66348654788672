import styled from 'styled-components';
import {ExportExcelForm} from '../../../Components/Template';
import {checkBoxProps} from '../Participation/data';

export default function ExcelDownload() {
	return (
		<Container>
			<ExportExcelForm
				type='paticipation'
				checkBoxProps={checkBoxProps}
			/>
		</Container>
	);
}

const Container = styled.div({
	padding: '50px 20px',
});
