import styled from 'styled-components';
import {viewSize} from '../../../config';

export const FirstSection = styled.div``;

export const SecondSection = styled.div``;

export const ThirdSection = styled.div``;

export const TableWrapper = styled.div`
	margin: 1.5rem 0;
`;

export const CardContentWrapper = styled.div`
	margin-bottom: 4rem;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	> button {
		width: 276px;
		height: 60px;
	}
	@media only screen and (max-width: ${viewSize.mobile}) {
		> button {
			width: 190px;
			height: 60px;
		}
	}
`;
