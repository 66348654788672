import {tdProps, thProps, cardTitleProps} from '../../common';

export const coursePrize = {
	title: {
		text: {children: '종목별 시상안내', ...cardTitleProps},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '부별'},
			{...thProps, children: '시상내역'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스 부문'},
				{...tdProps, children: '남자/여자'},
				{
					...tdProps,
					children:
						'1-3위: 상금, 상장, 트로피',
				},
			],
			[
				{...tdProps, children: '하프코스 부문'},
				{...tdProps, children: '남자/여자'},
				{
					...tdProps,
					children:
						'1-3위: 상금, 상장, 트로피',
				},
			],
			[
				{...tdProps, children: '10km코스 부문'},
				{...tdProps, children: '남자/여자'},
				{
					...tdProps,
					children:
						'1-3위: 상금, 상장, 트로피',
				},
			],
			[
				{...tdProps, children: '5km코스 부문'},
				{...tdProps, children: '남자/여자'},
				{
					...tdProps,
					children: '1-3위: 상장, 부상',
				},
			],
			[
				{...tdProps, children: '단체상'},
				{...tdProps, children: '단체'},
				{
					...tdProps,
					children: '상금',
				},
			],

			[
				{...tdProps, children: '행운상'},
				{...tdProps, children: ''},
				{...tdProps, children: '추첨'},
			],
		],
	},
};

export const prizeInfo = {
	title: {
		text: {children: '시상금 안내', ...cardTitleProps},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '순위'},
			{...thProps, children: '시상'},
			{...thProps, children: '비고'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스 부문\n(남/여)', rowSpan: '4'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '40만원, 상장, 트로피'},
				{
					...tdProps,
					children: ``,
					rowSpan: '4',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '30만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '20만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4-10위'},
				{...tdProps, children: '부상(특산물), 상장'},
			],

			[
				{...tdProps, children: '하프코스 부문\n(남/여)', rowSpan: '4'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '20만원, 상장, 트로피'},
				{
					...tdProps,
					children: ``,
					rowSpan: '4',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '10만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '7만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4-10위'},
				{...tdProps, children: '부상(특산물), 상장'},
			],

			[
				{...tdProps, children: '10km코스 부문\n (남/여)', rowSpan: '4'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '20만원, 상장, 트로피'},
				{
					...tdProps,
					children: ``,
					rowSpan: '4',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '10만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '7만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4-10위'},
				{...tdProps, children: '부상(특산물), 상장'},
			],
			[
				{...tdProps, children: '5km코스 부문\n (남/여)', rowSpan: '3'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '부상(특산물), 상장'},
				{
					...tdProps,
					children: ``,
					rowSpan: '3',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '부상(특산물), 상장'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '부상(특산물), 상장'},
			],

			[
				{...tdProps, children: '단체참가상', rowSpan: '2'},
				{...tdProps, children: '30명 이상'},
				{...tdProps, children: '20만원'},
				{
					...tdProps,
					children: `5km 참가자 제외`,
					rowSpan: '2',
				},
			],
			[
				{...tdProps, children: '20명 이상'},
				{...tdProps, children: '10만원'},
			],
			[
				{...tdProps, children: '단체부스'},
				{...tdProps, children: '30명 이상'},
				{...tdProps, children: ' '},
				{...tdProps, children: '부스 1동 제공'},
			],
			[
				{...tdProps, children: '행운상'},
				{...tdProps, children: ' '},
				{...tdProps, children: `추첨`},
				{...tdProps, children: ' '},
			],
		],
		// descriptions: [
		// 	{
		// 		content: {
		// 			children: '5km 코스는 기록 미측정으로 시상에서 제외됩니다.',
		// 		},
		// 	},
		// 	{
		// 		content: {
		// 			children:
		// 				'남, 여 부문 200명 이하 참가시 시상은 1 - 10위까지만 진행됩니다.',
		// 		},
		// 	},
		// ],
	},
};
