import {Input, Row, Select} from 'antd';
import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {colorPalette, viewSize} from '../../../config';
import TextForm from '../TextForm';
import {Container, FormWrapper, RowWrapper, TextWrapper} from './style';

export default function EmailInput({
	text,
	englishText,
	category = 'default',
	info,
	setInfo,
	warnText,
}) {
	const [emailId, setEmailId] = useState('');
	const [emailCompany, setEmailCompany] = useState('');
	const [emailSelect, setEmailSelect] = useState('직접입력');
	const textProps = {
		text,
		englishText,
		category,
	};

	const onChangeInfoEmailCompany = (value) => {
		if (value === '직접입력') {
			const newEmail = info?.email?.split('@');
			setInfo((prev) => ({
				...prev,
				email: `${newEmail?.[0] ?? ''}@`,
			}));
		} else {
			const newEmail = info?.email?.split('@');
			setInfo((prev) => ({
				...prev,
				email: `${newEmail?.[0] ?? ''}@${value}`,
			}));
		}
	};

	const onChangeInfoEmailId = (value) => {
		const newEmail = info?.email?.split('@');
		setInfo((prev) => ({
			...prev,
			email: `${value}@${newEmail?.[1] ?? ''}`,
		}));
	};

	const onChangeSelect = (value) => {
		setEmailSelect(value);
		if (value === '직접입력') {
			setEmailCompany('');
		} else {
			setEmailCompany(value);
		}
	};

	useEffect(() => {
		if (info.email !== '') {
			const newEmailId = info.email?.split('@')?.[0];
			const newEmailCompany = info.email?.split('@')?.[1];
			setEmailId(newEmailId);
			setEmailCompany(newEmailCompany);
			if (
				newEmailCompany === 'gmail.com' ||
				newEmailCompany === 'naver.com' ||
				newEmailCompany === 'hanmail.net' ||
				newEmailCompany === 'daum.net'
			) {
				setEmailSelect(newEmailCompany);
			} else {
				setEmailSelect('직접입력');
			}
		}
	}, []);

	useEffect(() => {
		setInfo((prev) => ({
			...prev,
			email: `${emailId}@${emailCompany}`,
		}));
	}, [emailId, emailCompany]);

	return (
		<Container>
			<RowWrapper>
				<TextWrapper>{text && <TextForm {...textProps} />}</TextWrapper>
				<BoxWrapper>
					<StyledInput
						placeholder='email'
						value={emailId}
						style={{fontSize: '18px'}}
						onChange={(event) => {
							const value = event.target.value;
							// onChangeInfoEmailId(value);
							setEmailId(value);
						}}
					/>
					<DashBox>@</DashBox>
					<StyledInput
						value={emailCompany}
						placeholder='naver.com'
						onChange={(event) => {
							const value = event.target.value;
							// onChangeInfoEmailCompany(value);
							setEmailCompany(value);
						}}
						disabled={emailSelect !== '직접입력'}
					/>
					<EmailAddressSelect
						placeholder='선택'
						value={{value: emailSelect, label: emailSelect}}
						onChange={onChangeSelect}
						options={[
							{value: '직접입력', label: '직접입력'},
							{value: 'naver.com', label: 'naver.com'},
							{value: 'gmail.com', label: 'gmail.com'},
							{value: 'hanmail.net', label: 'hanmail.net'},
							{value: 'daum.net', label: 'daum.net'},
						]}
					/>
				</BoxWrapper>
			</RowWrapper>
			{warnText?.children !== '' && (
				<Row justify='start' style={{width: '100%'}}>
					<ErrorMeassage>{warnText?.children}</ErrorMeassage>
				</Row>
			)}
		</Container>
	);
}

const EmailAddressSelect = styled(Select)({
	marginLeft: 10,
	'&&&& .ant-select-selector': {
		borderColor: '#9B9B9B',
		height: '3.25rem !important',
		display: 'nowrap',
		width: 300,
		borderRadius: 2,
		padding: 10,
		fontSize: '18px',
		'@media screen and (max-width: 992px)': {
			width: 95,
			fontSize: '16px',
		},
	},
	width: 300,
	'@media screen and (max-width: 992px)': {
		width: 95,
	},
});

const BoxWrapper = styled(Row)({
	alignItems: 'center',
	justifyContent: 'space-between',
	flexFlow: 'nowrap',
	width: '75%',
	'@media screen and (max-width: 992px)': {
		width: '100%',
		justifyContent: 'space-between',
	},
});

const DashBox = styled.div({
	fontSize: 20,
	margin: '0px 10px',
	'@media screen and (max-width: 450px)': {
		margin: '0px 9px',
	},
});

const StyledInput = styled(Input)({
	width: 265,
	height: '3.25rem !important',
	borderRadius: 0,
	borderColor: '#9B9B9B',
	fontSize: '18px',
	padding: '10px 16px',
	'@media screen and (max-width: 450px)': {
		width: 95,
		fontSize: '16px',
	},
});

const ErrorMeassage = styled.p({
	color: '#ff0000',
	marginLeft: 300,
	'@media screen and (max-width: 450px)': {
		marginLeft: 0,
	},
});
