import React, {useState, useEffect} from 'react';
import {Select, Space, Table} from 'antd';
import * as S from './style';
import {statistics} from '../../../api/admin';
import {ResponsiveBar} from '@nivo/bar';

const {Column} = Table;

const Statistics = () => {
	const [statistic, setStatistic] = useState([]);
	useEffect(() => {
		(async () => {
			let res = await statistics({id: 2});
			setStatistic(res.data);
		})();
	}, []);

	const columns = [
		'구분',
		'5km코스',
		'10km코스',
		'하프코스',
		'풀코스',
		'합계',
	];
	const sizes = ['구분', '80', '85', '90', '95', '100', '105', '110', '115', '합'];
	const rows = ['전체', '입금', '남성', '여성'];
	const rows_region = [
		'구분',
		'전체',
		'남성',
		'여성',
		'풀코스',
		'하프코스',
		'10km코스',
		'5km코스',
		'입금',
		'미입금',
		'비율',
	]
	const regions = [
		'서울',
		'부산',
		'대구',
		'인천',
		'광주',
		'대전',
		'울산',
		'세종',
		'경기',
		'강원',
		'충북',
		'충남',
		'전북',
		'전남',
		'경북',
		'경남',
		'제주',
	];

	const handleChange = (value) => {
		(async () => {
			let res = await statistics({id: 1 + (value - 2024)});
			setStatistic(res.data);
		})();
	};

	let chartData = []
	{statistic.region && (
		regions.map((item, idx) => {
			let dic = {
				'region': item,
				'남성': statistic.region[idx][1],
				'여성': statistic.region[idx][2],
			}
			chartData.push(dic)
		})
	)};

	return (
		<S.Container>
			<br />
			<Space wrap>
				<Select
					defaultValue='2025'
					style={{
						width: 200,
						'padding-left': '20px',
					}}
					onChange={(value) => {
						handleChange(value)
					}}
					options={[
						{
							value: '2024',
							label: '제 18회',
						},
						{
							value: '2025',
							label: '제 19회',
						},
					]}
				/>
			</Space>

			<br />
			<div style={{padding: '0 20px'}}>&nbsp;▼코스별 참가자 현황</div>
			<table style={{padding: '5px 20px', width: '100%'}}>
				<thead>
					<tr>
						{columns.map((item) => {
							return <th style={{
								'text-align': 'center',
								'background': '#0048ad',
								'color': 'white',
								'border': '1px solid #444444',
							}}
							>{item}</th>
						})}
					</tr>
				</thead>
				<tbody>
					{statistic.course && statistic.course.map((item) => {
						return (
							<tr>
								<td style={{
									width: '15%',
									'text-align': 'center',
									'background': '#0048ad',
									'color': 'white',
									'border': '1px solid #444444',
								}}
								><b>{item[0]}</b></td>
								<td style={{
									width: '15%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[1]}</td>
								<td style={{
									width: '15%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[2]}</td>
								<td style={{
									width: '15%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[3]}</td>
								<td style={{
									width: '15%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[4]}</td>
								<td style={{
									width: '15%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[5]}</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			<br />
			<br />
			{/* <div style={{padding: '0 20px'}}>&nbsp;▼티셔츠 사이즈별 참가자 현황</div>
			<table style={{padding: '5px 20px', width: '100%'}}>
				<thead>
					<tr>
						{sizes.map((item) => {
							return <th style={{
								'text-align': 'center',
								'background': '#0048ad',
								'color': 'white',
								'border': '1px solid #444444',
							}}
							>{item}</th>
						})}
					</tr>
				</thead>
				<tbody>
					{statistic.option && statistic.option.map((item, idx) => {
						return (
							<tr>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'background': '#0048ad',
									'color': 'white',
									'border': '1px solid #444444',
								}}
								><b>{rows[idx]}</b></td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[0]}</td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[1]}</td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[2]}</td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[3]}</td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[4]}</td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[5]}</td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[6]}</td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[7]}</td>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[8]}</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			<br />  */}

			<br />
			<div style={{padding: '0 20px'}}>&nbsp;▼지역별 참가자 성별 그래프</div>
			{statistic.region && (
				<div style={{width: '100%', height: '700px', padding: '5px 20px'}}>			
					<ResponsiveBar
						data={chartData}
						keys={[
							'남성',
							'여성',
						]}
						indexBy='region'
						margin={{top: 20, right: 60, bottom: 50, left: 60}}
						//colors={{scheme: 'nivo'}}
						colors={['#aaceff', '#6981a1']}
						padding={0.3}
						valueScale={{type: 'linear'}}
						indexScale={{type: 'band', round: true}}
						colorBy='id'
						theme={{
							labels: {
								text: {
									fontSize: 14,
									fill: '#000000',
								},
							},
							axis: {
								ticks: {
									text: {
										fontSize: 15,
										fill: '#000000',
									},
								},
							},
						}}
						axisBottom={{
							tickSize: 3, // 값 설명하기 위해 튀어나오는 점 크기
							tickPadding: 5, // tick padding
							tickRotation: 0, // tick 기울기
							legendPosition: 'middle', // 글씨 위치
						}}
					/>
				</div>)
			}
			<br />

			<br />
			<div style={{padding: '0 20px'}}>&nbsp;▼지역별 참가자 현황</div>
			<table style={{padding: '5px 20px', width: '100%', height: '500px'}}>
				<thead>
					<tr>
						{rows_region.map((item) => {
							return <th style={{
								'text-align': 'center',
								'background': '#0048ad',
								'color': 'white',
								'border': '1px solid #444444',
							}}
							>{item}</th>
						})}
					</tr>
				</thead>
				<tbody>
					{statistic.region && statistic.region.map((item, idx) => {
						return (
							<tr>
								<td style={{
									width: '10%',
									'text-align': 'center',
									'background': '#0048ad',
									'color': 'white',
									'border': '1px solid #444444',
								}}
								><b>{regions[idx]}</b></td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[0]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[1]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[2]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[3]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[4]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[5]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[6]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[7]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[8]}</td>
								<td style={{
									width: '9%',
									'text-align': 'center',
									'border': '1px solid #444444',
									}}>{item[9]}%</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			<br />
		</S.Container>
	);
};

export default Statistics;