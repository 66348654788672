import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`;

export const Img = styled.img`
	width: fit-content;
`;

export const TextWrapper = styled.div``;
