import React, {useState, useEffect} from 'react';
import * as S from './style';
import {buttonProps, groupProps, participationProps} from './data';
import {Input, Button, Select, Form, Space, Table, DatePicker} from 'antd';
import {getGroup, updateGroup} from '../../../api/admin';
import {
	makeCourse,
	makeDay,
	makeGiftByCourse,
	makeMonth,
	makeYear,
	setGroupForm,
} from '../../../util';
import {postGroupParticipation} from '../../../api';
import dayjs from 'dayjs';
const dateFormat = 'YYYY-MM-DD';

const ModalGroupForm = ({id, onClick}) => {
	const [group, setGroup] = useState({
		name: '',
		representative: '',
		year: '',
		month: '',
		day: '',
		phone1: '',
		phone2: '',
		phone3: '',
		post_number: '',
		address: '',
		detail_address: '',
		depositor: '',
		email: '',
		password: '',
	});
	const [participation, setParticipation] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [num_deposited, setNumDeposited] = useState([0]);
	const [deposit_amount, setDepositAmount] = useState([0]);
	useEffect(() => {
		(async () => {
			if (id) {
				let res = await getGroup({id});
				let data = res.data;
				let participation_data = data.participation;
				let splitted_phone = res.data.phone.split('-');
				let splitted_birth = res.data.birth.split('-');

				setGroup({
					name: data.name,
					representative: data.representative,
					year: splitted_birth[0],
					month: splitted_birth[1],
					day: splitted_birth[2],
					phone1: splitted_phone[0],
					phone2: splitted_phone[1] ? splitted_phone[1] : '',
					phone3: splitted_phone[2] ? splitted_phone[2] : '',
					post_number: data.post_number,
					address: data.address,
					detail_address: data.detail_address,
					depositor: data.depositor,
					email: data.email,
					password: data.password,
				});

				let temp = [];
				var num_deposited = 0;
				participation_data.forEach((item, index) => {
					let splitted_phone = item.phone.split('-');
					// if (item.option && item.gift == '티셔츠') {
					// 	var gift = `${item.gift} - ${item.option}`;
					// } else {
					// 	gift = item.gift;
					// }
					if (item.is_deposit) {
						num_deposited++;
					}
					temp.push({
						dataIndex: index,
						check: false,
						id: item.id,
						name: item.name,
						birth: item.birth,
						gender: item.gender,
						phone1: splitted_phone[0],
						phone2: splitted_phone[1] ? splitted_phone[1] : '',
						phone3: splitted_phone[2] ? splitted_phone[2] : '',
						bib: item.bib ? item.bib : '',
						course: item.course,
						gift: '-',
						is_deposit: item.is_deposit,
						deleted: item.deleted,
						created: item.created,
						deposited: item.deposited,
						option: item.option,
					});
				});
				setParticipation(temp);
				setNumDeposited(num_deposited);
				setDepositAmount(data.deposit_amount);
			}
			setIsLoading(false);
		})();
	}, []);

	buttonProps.button.onClick = async () => {
		let temp_group = {
			...group,
			phone: `${group.phone1}-${group.phone2}-${group.phone3}`,
			birth: `${group.year}-${group.month}-${group.day}`,
		};
		let temp_participations = [];
		participation.forEach((item) => {
			temp_participations.push({
				...item,
				phone: `${item.phone1}-${item.phone2}-${item.phone3}`,
			});
		});
		let body = {
			...temp_group,
			participation: temp_participations,
		};
		let res = await updateGroup({id, body});
		onClick();
	};
	const onChangeGroup = (index, name, value) => {
		const newState = [...participation];
		newState[index][name] = value;
		setGroup(newState);
	};
	const handleAdd = () => {
		const newData = {
			key: Math.random(),
			dataIndex: participation.length,
			name: '',
			birth: '',
			phone1: '',
			phone2: '',
			phone3: '',
			id: undefined,
			gender: '',
			bib: '',
			course: '',
			gift: '',
			is_deposit: false,
			deleted: null,
			created: '',
		};

		setParticipation([...participation, newData]);
	};

	const handleDelete = (key) => {
		if (key) {
			const newData = participation.filter((item) => item.key !== key);
			setParticipation(newData);
		} else {
			alert(
				'해당 신청서는 기존에 있던 신청서입니다. 삭제 여부를 통한 임시 삭제 후 영구삭제해주세요.',
			);
		}
	};
	return (
		<S.Container>
			{!isLoading && (
				<Form
					layout={'vertical'}
					style={{flexDirection: 'column'}}
					initialValues={group}
					onFinish={async (values) => {
						let now = new Date();
						let participationData = participation.map((item) => {
							// if (item.gift.split(' - ').length == 2) {
							// 	var gift = item.gift.split(' - ')[0];
							// 	var option = item.gift.split(' - ')[1];
							// } else {
							// 	gift = item.gift;
							// 	option = null;
							// };
							// if (item.deleted) { return false };
							return {
								...item,
								phone: `${item.phone1}-${item.phone2}-${item.phone3}`,
								gift: '-',
								// option: option,
								deposited: !item.is_deposit
									? null
									: item.deposited
									? item.deposited
									: `${now.getFullYear()}-${(
											'0' +
											(now.getMonth() + 1)
										).slice(-2)}-${(
											'0' + now.getDate()
										).slice(-2)}`,
								deleted: item.deleted,
							};
						});
						let body = {
							...values,
							phone: `${values.phone1}-${values.phone2}-${values.phone3}`,
							birth: `${values.year}-${values.month}-${values.day}`,
							participation: participationData.filter(Boolean),
						};
						let verification =
							sessionStorage.getItem('verification');
						if (id) {
							let res = await updateGroup({
								id,
								body,
								verification,
							});
							if (!res.isSuccess) {
								return;
							}
						} else {
							let res = await postGroupParticipation({
								...body,
								verification,
							});
							if (!res.isSuccess) {
								return;
							}
						}
						onClick();
					}}
					// labelCol={{span: 6}}
					// wrapperCol={{span: 14}}
				>
					<Form.Item name={'name'} label={'이름'}>
						<Input />
					</Form.Item>
					<Form.Item name={'representative'} label={'대표자명'}>
						<Input />
					</Form.Item>
					<Form.Item name={'depositor'} label={'입금자명'}>
						<Input />
					</Form.Item>
					<Form.Item label={'대표자 연락처'}>
						<Space>
							<Form.Item name={'phone1'} noStyle>
								<Input />
							</Form.Item>
							<Form.Item name={'phone2'} noStyle>
								<Input />
							</Form.Item>
							<Form.Item name={'phone3'} noStyle>
								<Input />
							</Form.Item>
						</Space>
					</Form.Item>
					<Form.Item label={'대표자 생년월일'}>
						<Space>
							<Form.Item name={'year'} style={{width: '100px'}}>
								<Select options={makeYear()} />
							</Form.Item>
							<Form.Item name={'month'} style={{width: '80px'}}>
								<Select options={makeMonth()} />
							</Form.Item>
							<Form.Item name={'day'} style={{width: '80px'}}>
								<Select options={makeDay()} />
							</Form.Item>
						</Space>
					</Form.Item>
					<Form.Item name={'email'} label={'이메일'}>
						<Input />
					</Form.Item>
					<Form.Item name={'post_number'} label={'우편번호'}>
						<Input />
					</Form.Item>
					<Form.Item name={'address'} label={'주소'}>
						<Input />
					</Form.Item>
					<Form.Item name={'detail_address'} label={'상세주소'}>
						<Input />
					</Form.Item>
					<Form.Item name={'password'} label={'비밀번호'}>
						<Input />
					</Form.Item>
					<Button onClick={handleAdd}>추가</Button>
					<div style={{padding: '4px', 'font-weight': 'bolder'}}>
						입금자 수 : {num_deposited}
						명&nbsp;&nbsp;&nbsp;&nbsp;미입금 금액 : {deposit_amount}
						원
					</div>
					<Table dataSource={participation} scroll={{x: 1300}}>
						<Table.Column
							title={'이름'}
							align={'center'}
							render={(_, record) => {
								return (
									<Input
										style={{textAlign: 'center'}}
										value={record.name}
										onChange={(e) => {
											onChangeGroup(
												record.dataIndex,
												'name',
												e.target.value,
											);
										}}
									/>
								);
							}}
						/>
						<Table.Column
							align={'center'}
							title={'생년월일'}
							width={200}
							render={(_, record) => (
								<DatePicker
									value={
										record.birth ? dayjs(record.birth) : ''
									}
									placeholder={dayjs().format(dateFormat)}
									onChange={(value) => {
										onChangeGroup(
											record.dataIndex,
											'birth',
											value
												? dayjs(value).format(
														dateFormat,
												  )
												: '',
										);
									}}
								/>
							)}
						/>
						<Table.Column
							title={'성별'}
							align={'center'}
							render={(_, record) => (
								<Select
									style={{width: '100px'}}
									value={record.gender}
									options={[
										{label: '남성', value: '남성'},
										{label: '여성', value: '여성'},
									]}
									onChange={(e) => {
										onChangeGroup(
											record.dataIndex,
											'gender',
											e,
										);
									}}
								/>
							)}
						/>
						<Table.Column
							title={'연락처'}
							align={'center'}
							render={(_, record) => (
								<S.RowWrapper>
									<Input
										style={{textAlign: 'center'}}
										value={record.phone1}
										onChange={(e) => {
											onChangeGroup(
												record.dataIndex,
												'phone1',
												e.target.value,
											);
										}}
									/>
									<Input
										style={{textAlign: 'center'}}
										value={record.phone2}
										onChange={(e) => {
											onChangeGroup(
												record.dataIndex,
												'phone2',
												e.target.value,
											);
										}}
									/>
									<Input
										style={{textAlign: 'center'}}
										value={record.phone3}
										onChange={(e) => {
											onChangeGroup(
												record.dataIndex,
												'phone3',
												e.target.value,
											);
										}}
									/>
								</S.RowWrapper>
							)}
						/>
						<Table.Column
							title={'배번'}
							align={'center'}
							render={(_, record) => (
								<Input
									style={{textAlign: 'center'}}
									value={record.bib}
									onChange={(e) => {
										onChangeGroup(
											record.dataIndex,
											'bib',
											e.target.value,
										);
									}}
								/>
							)}
						/>
						<Table.Column
							title={'종목'}
							align={'center'}
							render={(_, record) => (
								<Select
									style={{width: '100px'}}
									value={record.course}
									options={makeCourse()}
									onChange={(e) => {
										onChangeGroup(
											record.dataIndex,
											'course',
											e,
										);
									}}
								/>
							)}
						/>
						{/* <Table.Column
							title={'기념품'}
							align={'center'}
							render={(_, record) => (
								<Select
									style={{width: '110px'}}
									value={record.gift}
									options={makeGiftByCourse(record.course)}
									onChange={(e) => {
										onChangeGroup(
											record.dataIndex,
											'gift',
											e,
										);
									}}
								/>
							)}
						/> */}
						{/* <Table.Column
							title={'옵션'}
							align={'center'}
							render={(_, record) => (
								<Input
									disabled
									style={{textAlign: 'center'}}
									value={record.option}
									onChange={(e) => {
										onChangeGroup(
											record.dataIndex,
											'option',
											e.target.value,
										);
									}}
								/>
							)}
						/> */}
						<Table.Column
							title={'입금여부'}
							align={'center'}
							render={(_, record) => (
								<Select
									value={record.is_deposit}
									options={[
										{value: true, label: '입금'},
										{value: false, label: '미입금'},
									]}
									onChange={(e) => {
										onChangeGroup(
											record.dataIndex,
											'is_deposit',
											e,
										);
									}}
								/>
							)}
						/>
						<Table.Column
							title={'입금확인날짜'}
							align={'center'}
							render={(_, record) => {
								return (
									<Input
										disabled
										style={{textAlign: 'center'}}
										value={record.deposited}
									/>
								);
							}}
						/>
						<Table.Column
							title={'삭제여부'}
							align={'center'}
							render={(_, record) => (
								<Select
									defaultValue={null}
									options={[
										{value: null, label: '선택'},
										{
											value: dayjs().format(),
											label: '삭제',
										},
									]}
									onChange={(e) => {
										onChangeGroup(
											record.dataIndex,
											'deleted',
											e,
										);
									}}
								/>
							)}
						/>
					</Table>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button htmlType={'submit'}>
							{id ? '수정' : '생성'}
						</Button>
					</div>
				</Form>
			)}
		</S.Container>
	);
};

export default ModalGroupForm;
