import dayjs from 'dayjs';
import React from 'react';
import * as S from './style';

const Pic = ({images = [], onClick}) => {
	return (
		<S.Container>
			{images.map((image) => {
				return (
					<S.Link
						onClick={() =>
							onClick({
								id: image.id,
								isPassword: image.is_password,
							})
						}
					>
						<S.ImgWrapper>
							<S.Img src={image.image} />
							<S.ImgTitle>{image.title}</S.ImgTitle>
							<S.ImgCreated>
								{dayjs(image.created)?.format('YYYY-MM-DD')}
							</S.ImgCreated>
						</S.ImgWrapper>
					</S.Link>
				);
			})}

			{/* {images.map((image) => {
				return (
                    <S.Link href={image.link}>
						<S.ImgWrapper>
							<S.Img
								src={'http://localhost:8000' + image.image}
							/>
							<S.ImgTitle>{image.title}</S.ImgTitle>
							<S.ImgCreated>
								{dayjs(image.created)?.format(
									'YYYY-MM-DD',
								)}
							</S.ImgCreated>
						</S.ImgWrapper>
                    </S.Link>

					<S.Link href={image.link}>
						<S.ImgWrapper>
							<S.Img
								src={'http://13.124.106.193' + image.image}
							/>
							<S.ImgTitle>{image.title}</S.ImgTitle>
							<S.ImgCreated>{image.created}</S.ImgCreated>
						</S.ImgWrapper>
					</S.Link>
				);
			})}
			{images.map((image) => {
				return (
					<S.Link href={image.link}>
						<S.ImgWrapper>
							<S.Img
								src={'http://13.124.106.193' + image.image}
							/>
							<S.ImgTitle>{image.title}</S.ImgTitle>
							<S.ImgCreated>{image.created}</S.ImgCreated>
						</S.ImgWrapper>
					</S.Link>
				);
			})}
			{images.map((image) => {
				return (
					<S.Link href={image.link}>
						<S.ImgWrapper>
							<S.Img
								src={'http://13.124.106.193' + image.image}
							/>
							<S.ImgTitle>{image.title}</S.ImgTitle>
							<S.ImgCreated>{image.created}</S.ImgCreated>
						</S.ImgWrapper>
					</S.Link>
				);
			})} */}
		</S.Container>
	);
};

export default Pic;
