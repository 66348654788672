import React from 'react';
import * as S from './style';
import {Text} from '../../Atom';
import {isMobile} from 'react-device-detect';
import styled from 'styled-components';
import {colorPalette, fontWeight} from '../../../config';
const RowGenerate = ({items = []}) => {
	if (items.length == 1) {
		return items.map((item) => (
			<S.ItemWrapper>
				<S.SolidHead>
					<Text {...item.title} fontFamily='Gmarket' />
				</S.SolidHead>
				{item.content && (
					<S.SolidContent>
						<Text {...item.content} />
					</S.SolidContent>
				)}
				{item.contents && (
					<S.MultipleContent>
						{item.contents.map((content) => {
							if (isMobile && content?.type === 'mobileBank') {
								return (
									<MobileBankText>
										입금은행 : 농협 예금주 : 여수마라톤추진위원회
										계좌번호 : 355-0091-2634-43 <br />
										입금은행 : 새마을 예금주 : 여수마라톤추진위원회
										계좌번호 : 9002-2057-8051-1
									</MobileBankText>
								);
							}
							if (isMobile && content?.type === 'mobileCourse') {
								return (
									<MobileCourseText>
										풀코스 부문: 35,000원/ 하프코스 부문:
										30,000원/ 10km 코스 부문: 20,000원/ 5km
										코스 부문: 10,000원
									</MobileCourseText>
								);
							}
							return <Text {...content} />;
						})}
					</S.MultipleContent>
				)}
			</S.ItemWrapper>
		));
	}

	return items.map((item) => (
		<S.ItemsWrapper>
			<S.Head>
				<Text {...item.title} fontFamily='Gmarket' />
			</S.Head>
			<S.Content>
				<Text {...item.content} />
			</S.Content>
		</S.ItemsWrapper>
	));
};

// items 는 2차 배열 [ [], [] ]
const SelectTable = ({
	items = [],
	display = {desktop: 'flex', tablet: 'flex', mobile: 'flex'},
}) => {
	return (
		<S.Container display={display}>
			{items.map((item) => (
				<S.Row>
					<RowGenerate items={item} />
				</S.Row>
			))}
		</S.Container>
	);
};

export default SelectTable;

const MobileBankText = styled.p({
	textAlign: 'center',
	color: colorPalette.primary,
	fontWeight: fontWeight.bold,
	lineHeight: 1.4,
});

const MobileCourseText = styled.p({
	textAlign: 'center',
	lineHeight: 1.4,
	color: colorPalette.primary,
	fontWeight: fontWeight.normal,
});
