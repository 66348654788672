import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from 'react-router-dom';
import {router} from './Router';
import {Footer, Nav} from './Components/Template';
import {footerProps, navProps} from './config';
import GlobalStyle from './config/global';
const root = ReactDOM.createRoot(document.getElementById('root'));
const isAdmin = window.location.pathname.includes('admin');
root.render(
	<React.StrictMode>
		<GlobalStyle />
		<Nav {...navProps} />
		<RouterProvider router={router} />
		{!isAdmin && <Footer {...footerProps} />}
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
