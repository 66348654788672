import React, {useEffect, useState} from 'react';
import * as S from './style';
import {
	categoryInit,
	checkBoxProps,
	columns,
	courseFilter,
	depositFilter,
	depositInit,
	genderFilter,
	orderInit,
	searchProps,
	selectProps,
	tableProps,
} from './data';
import {
	Radio,
	Checkbox,
	Button,
	Input,
	Select,
	Table,
	Space,
	Button as AntdButton,
	Modal,
	Typography,
} from 'antd';
import {GroupTable} from '../../../Components/Organism/GroupForm';
import {Pagination} from '../../../Components/Organism';
import {
	exportParticipation,
	getListParticipation,
	getListPerson,
	setDepositParticipation,
	setDepositParticipations,
	tempDeleteParticipation,
	tempDeleteParticipations,
} from '../../../api/admin';
import {generateAdminParticipationTable} from '../../../util/generator';
import {setToggleCheck} from '../../../util';
import {checkBinding} from '../../../util/binding';
import {dictToList, dictToStr} from '../../../util/postProcess';
import {ModalGroupForm, ModalPersonForm} from '../index';
import {deleteBoard} from '../../../api/board';
import RecordForm from '../RecordForm';
import {
	ExportExcelForm,
	FliterBox,
	TableHeaderBox,
} from '../../../Components/Template';

const Person = () => {
	const [participation, setParticipation] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [isAllCheck, setIsAllCheck] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [action, setAction] = useState('');
	const [search, setSearch] = useState('');
	const [fields, setFields] = useState([]);
	const [order, setOrder] = useState('id');
	const [category, setCategory] = useState(categoryInit);
	const [isDeposit, setIsDeposit] = useState('all');
	const [kind, setKind] = useState('all');
	const [deposit, setDeposit] = useState(depositInit);
	const [modal, setModal] = useState(false);
	const [record, setRecord] = useState(0);
	const [select, setSelect] = useState({id: 0, category: 'person'});
	const [filter, setFilter] = useState({
		gender: '',
		is_deposit: '',
		course: '',
	});
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [ordering, setOrdering] = useState('');
	const [participationModal, setParticipationModal] = useState(false);
	const [isGroupCreateModal, setIsGroupCreateModal] = useState(false);
	const [isExcelDownloadModal, setIsExcelDownLoadModal] = useState(false);
	useEffect(() => {
		(async () => {
			let res = await getListPerson({
				page,
				filter,
				search,
				ordering,
			});
			if (!res.isSuccess) {
				return;
			}
			let data = res.data.results.map((item, idx) => {
				item.no = res.data.count - (page - 1) * 10 - idx;
				item.key = item.id;
				return item;
			});
			setParticipation(data);
			setTotal(res.data.count);
		})();
	}, [page, toggle, filter, search, ordering]);

	tableProps.trs = generateAdminParticipationTable({
		participations: participation,
		setParticipation: setParticipation,
		depositApi: setDepositParticipation,
		deleteApi: tempDeleteParticipation,
		current: page,
		total: total,
		setToggle: setToggle,
		toggle: toggle,
		setSelect,
		setModal,
	});
	tableProps.ths[0].onChange = () => {
		setToggleCheck(participation, setParticipation, setIsAllCheck);
	};
	tableProps.ths[0].value = isAllCheck;

	const doAction = async () => {
		switch (action) {
			case 'delete':
				await tempDeleteParticipations({ids: selectedRowKeys});
				break;
			case 'set_deposit':
				await setDepositParticipations({
					ids: selectedRowKeys,
					is_deposit: true,
				});
				break;
			case 'unset_deposit':
				await setDepositParticipations({
					ids: selectedRowKeys,
					is_deposit: false,
				});
				break;
			default:
				alert('액션을 선택해주세요.');
		}
		setToggle(!toggle);
	};

	const setSorter = (sorts) => {
		if (!Array.isArray(sorts)) {
			sorts = [sorts];
		}
		let sortList = sorts.map((item) => {
			if (item.order) {
				return `${item.order === 'ascend' ? '' : '-'}${item.field}`;
			}
		});

		if (sortList) {
			setOrdering(sortList.join(','));
		}
	};

	return (
		<S.Container>
			<FliterBox filter={filter} setFilter={setFilter} />
			<S.ButtonBox>
				<Space>
					<Button onClick={() => setParticipationModal(true)}>
						개인 신청서 생성
					</Button>
					<Button
						type='primary'
						onClick={() => setIsExcelDownLoadModal(true)}
					>
						개인 엑셀 다운로드
					</Button>
				</Space>
			</S.ButtonBox>
			<TableHeaderBox
				action={action}
				options={selectProps.options}
				setAction={setAction}
				setSearch={setSearch}
				doAction={doAction}
			/>

			<Table
				scroll={{x: 1300}}
				rowSelection={{
					selectedRowKeys,
					onChange: (e) => {
						setSelectedRowKeys(e);
					},
				}}
				style={{margin: '1rem'}}
				dataSource={participation}
				onChange={(pagination, filters, sorter, extra) => {
					setPage(pagination.current);
					setSorter(sorter);
				}}
				pagination={{
					defaultCurrent: 1,
					total: total,
					showTotal: (total) => `${total} 명`,
				}}
			>
				{columns.map((col) => {
					if (col.dataIndex === 'name') {
						return (
							<Table.Column
								{...col}
								render={(text, record) => (
									<a
										onClick={() => {
											setSelect({
												id: record.group
													? record.group.id
													: record.person.id,
												category: record.group
													? 'group'
													: 'person',
											});
										}}
									>
										{text}
									</a>
								)}
							/>
						);
					}
					return <Table.Column align={'center'} {...col} />;
				})}

				<Table.Column
					title={'입금여부'}
					align={'center'}
					dataIndex={'is_deposit'}
					render={(record) => (record ? '✅' : '❌')}
				/>
				<Table.Column
					title={'입금일'}
					align={'center'}
					dataIndex={'deposited'}
					sorter={{multiple: 13}}
				/>
				<Table.Column
					title={'액션'}
					key={'action'}
					align={'center'}
					render={(_, record) => (
						<Space size='small'>
							<AntdButton
								onClick={() => {
									setSelect({
										id: record.group
											? record.group.id
											: record.person.id,
										category: record.group
											? 'group'
											: 'person',
									});
								}}
							>
								수정
							</AntdButton>
							{/* <AntdButton
								onClick={() => {
									setRecord(record);
								}}
							>
								기록
							</AntdButton> */}
							<AntdButton
								onClick={async () => {
									await setDepositParticipation({
										id: record.id,
									});
									setToggle(!toggle);
								}}
							>
								입금
							</AntdButton>
							<AntdButton
								onClick={async () => {
									await tempDeleteParticipation({
										id: record.id,
									});
									setToggle(!toggle);
								}}
							>
								삭제
							</AntdButton>
						</Space>
					)}
				/>
			</Table>
			{participationModal && (
				<Modal
					width={'90%'}
					open={true}
					onCancel={() => {
						setParticipationModal(false);
					}}
					footer={[]}
				>
					<ModalPersonForm
						onClick={() => {
							setParticipationModal(false);
							setToggle(!toggle);
						}}
					/>
				</Modal>
			)}
			{select.id !== 0 && (
				<Modal
					width={'90%'}
					open={true}
					onCancel={() => {
						setSelect({...select, id: 0});
					}}
					footer={[]}
				>
					{select.category === 'person' ? (
						<ModalPersonForm
							person={select.id}
							onClick={() => {
								setSelect({...select, id: 0});
								setToggle(!toggle);
							}}
						/>
					) : (
						<ModalGroupForm
							id={select.id}
							onClick={() => {
								setSelect({...select, id: 0});
								setToggle(!toggle);
							}}
						/>
					)}
				</Modal>
			)}
			{record !== 0 && (
				<Modal
					open={true}
					onCancel={() => {
						setRecord(0);
						setToggle(!toggle);
					}}
					footer={[]}
				>
					<RecordForm
						onOk={() => {
							setRecord(0);
							setToggle(!toggle);
						}}
						state={record}
					/>
				</Modal>
			)}
			<Modal
				open={isExcelDownloadModal}
				onCancel={() => {
					setIsExcelDownLoadModal(false);
				}}
				footer={[]}
			>
				<ExportExcelForm
					checkBoxProps={checkBoxProps}
					type='person'
				/>
			</Modal>
		</S.Container>
	);
};

export default Person;
