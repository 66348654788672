export const adminMenuLeftList = [
	{
		name: '전체참가신청서',
		path: '/admin/participation',
	},
	{
		name: '단체신청서',
		path: '/admin/group',
	},
	{
		name: '개인신청서',
		path: '/admin/person',
	},
	{
		name: '입금자명단',
		path: '/admin/Depositor',
	},
	{
		name: '삭제명단',
		path: '/admin/deleted-participation',
	},
	{
		name: '통계자료',
		path: '/admin/statistics',
	},
	{
		name: '엑셀다운',
		path: '/admin/excel-download',
	},
	{
		name: '봉사신청서',
		path: '/admin/volunteer',
	},
	{
		name: '기록조회',
		path: '/admin/record',
	},
	{
		name: '게시판',
		path: '/admin/board',
	},
];

export const adminMenuRightList = [
	{
		name: '홈페이지 관리',
		path: '/admin/user',
	},
	{
		id: 'logout',
		name: '로그아웃',
	},
	{
		id: 'homepage',
		name: '홈페이지로 이동',
	},
];
