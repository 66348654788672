import {Button, Input, Row, Select, Space, Table} from 'antd';
import dayjs from 'dayjs';
import {isEqual} from 'lodash';
import {useState} from 'react';
import styled from 'styled-components';
import {colorPalette} from '../../../../config';
const dateFormat = 'YYYY-MM-DD';

export default function V2GroupTable({
	newGroup,
	setNewGroup,
	isUpdate,
	isUpdateGift = false,
}) {
	const {Option} = Select;
	const [select, setSelect] = useState({
		selectedRowKeys: [],
		selectedRows: [],
	});
	const addGroupItem = () => {
		setNewGroup((prev) => {
			return prev.concat([
				{
					key: `${
						prev.length ? Number(prev[prev.length - 1]?.key) + 1 : 0
					}`,
					name: '',
					gender: '',
					birth: '',
					phone1: '010',
					phone2: '',
					phone3: '',
					course: '',
					gift: '',
				},
			]);
		});
	};

	const onChangeValue = (key, value, name) => {
		const newGroupValue = newGroup.map((prevItem) => {
			if (prevItem.key === key) {
				return {
					...prevItem,
					[name]: value,
				};
			}
			return prevItem;
		});
		setNewGroup(newGroupValue);
	};

	const deleteGroupItem = (key) => {
		const filterGroup = newGroup.filter((item) => item.key !== key);
		setNewGroup(filterGroup);
		setSelect();
	};

	const selectDelete = () => {
		const filterItem = newGroup?.filter((item) => {
			return select?.selectedRows?.every((selectRow) => {
				return selectRow.key !== item.key;
			});
		});

		if (isEqual(newGroup, select.selectedRows)) {
			setSelect({
				selectedRowKeys: [],
				selectedRows: [],
			});
			setNewGroup([]);
		} else {
			setSelect();
			setNewGroup(filterItem);
		}
	};

	const onChangeSelction = (selectedRowKeys, selectedRows) => {
		setSelect({selectedRowKeys, selectedRows});
	};

	const onClickAllItem = () => {
		setSelect({
			selectedRowKeys: newGroup.map((item) => item.key),
			selectedRows: newGroup,
		});
	};

	const getCheckboxProps = (record) => ({
		disabled: record.isDeposit,
		// Column configuration not to be checked
		name: record.name,
	});

	const getGiftOpions = (index) => {
		// if (newGroup?.[index]?.course === '10km코스') {
		// 	return [{name: '힙색', value: '힙색'}];
		// }
		// if (newGroup?.[index]?.course === '5km코스') {
		// 	return [
		// 		{name: '티셔츠 - 80', value: '티셔츠 - 80'},
		// 		{name: '티셔츠 - 85', value: '티셔츠 - 85'},
		// 		{name: '티셔츠 - 90', value: '티셔츠 - 90'},
		// 		{name: '티셔츠 - 95', value: '티셔츠 - 95'},
		// 		{name: '티셔츠 - 100', value: '티셔츠 - 100'},
		// 		{name: '티셔츠 - 105', value: '티셔츠 - 105'},
		// 		{name: '티셔츠 - 110', value: '티셔츠 - 110'},
		// 		{name: '티셔츠 - 115', value: '티셔츠 - 115'},
		// 		// {name: '티셔츠 - 120', value: '티셔츠 - 120'},
		// 		// {name: '티셔츠 - 125', value: '티셔츠 - 125'},
		// 		// {name: '티셔츠 - 130', value: '티셔츠 - 130'},
		// 	];
		// }
		// if (newGroup?.[index]?.course === '풀코스') {
		// 	return [{name: '힙색', value: '힙색'}];
		// }
		return [{name: '준비중', value: '준비중'}];
	};

	const autoHypenPhone = (value) => {
		value = value.replace(/[^0-9]/g, '');
		var newValue = '';
		if (value.length < 5) {
			return value;
		} else if (value.length < 7) {
			newValue += value.substr(0, 4);
			newValue += '-';
			newValue += value.substr(4);
			return newValue;
		} else {
			newValue += value.substr(0, 4);
			newValue += '-';
			newValue += value.substr(4, 2);
			newValue += '-';
			newValue += value.substr(6);
			return newValue;
		}
	};

	const columns = [
		{
			title: '성명',
			dataIndex: 'name',
			render: (text, item) => {
				return (
					<Input
						value={text}
						onChange={(event) => {
							onChangeValue(
								item.key,
								event.target.value.replace(
									/[^\wㄱ-ㅎ|ㅏ-ㅣ|가-힣|ᆞ|ᆢ|ㆍ|ᆢ|ᄀᆞ|ᄂᆞ|ᄃᆞ|ᄅᆞ|ᄆᆞ|ᄇᆞ|ᄉᆞ|ᄋᆞ|ᄌᆞ|ᄎᆞ|ᄏᆞ|ᄐᆞ|ᄑᆞ|ᄒᆞ]/gi,
									'',
								),
								'name',
							);
						}}
						placeholder='홍길동'
					/>
				);
			},
		},
		{
			title: '성별',
			dataIndex: 'gender',
			render: (text, item) => (
				<Select
					defaultValue={text === '' ? null : text}
					placeholder='선택'
					style={{
						width: 100,
					}}
					onChange={(value) => {
						onChangeValue(item.key, value, 'gender');
					}}
					options={[
						{name: '남성', value: '남성'},
						{name: '여성', value: '여성'},
					]}
				/>
			),
		},
		{
			title: '생년월일',
			dataIndex: 'birth',
			render: (text, item) => (
				<Input
					value={text}
					maxLength={10}
					placeholder={dayjs().format(dateFormat)}
					onChange={(event) =>
						onChangeValue(
							item.key,
							autoHypenPhone(event.target.value),
							//dayjs(value).format(dateFormat),
							'birth',
						)
					}
				/>
			),
		},
		{
			title: '휴대폰 번호',
			dataIndex: 'phone',
			width: '300px',
			render: (_, item) => {
				return (
					<Input.Group compact>
						<Select
							value={item.phone1}
							defaultValue='010'
							onChange={(value) => {
								onChangeValue(item.key, value, 'phone1');
							}}
						>
							<Option value='010'>010</Option>
							<Option value='011'>011</Option>
						</Select>

						<Input
							value={item.phone2}
							style={{width: 100, textAlign: 'center'}}
							placeholder='0000'
							maxLength={4}
							onChange={(event) => {
								onChangeValue(
									item.key,
									event.target.value.replace(/[^0-9]/g, ''),
									'phone2',
								);
							}}
						/>

						<Input
							value={item.phone3}
							className='site-input-right'
							style={{
								width: 100,
								textAlign: 'center',
							}}
							placeholder='0000'
							maxLength={4}
							onChange={(event) => {
								onChangeValue(
									item.key,
									event.target.value.replace(/[^0-9]/g, ''),
									'phone3',
								);
							}}
						/>
					</Input.Group>
				);
			},
		},
		{
			title: '종목',
			dataIndex: 'course',
			render: (text, item) => (
				<Select
					style={{width: 100}}
					placeholder='선택'
					defaultValue={text === '' ? null : text}
					onChange={(value) => {
						onChangeValue(item.key, value, 'course');
					}}
					// 코스 비활성화 disabled: true
					options={[
						{name: '풀코스', value: '풀코스'},
						{name: '하프코스', value: '하프코스'},
						{name: '10km코스', value: '10km코스'},
						{name: '5km코스', value: '5km코스'},
					]}
					disabled={item?.isLoadData}
				></Select>
			),
		},
		// {
		// 	title: '기념품 옵션',
		// 	dataIndex: 'gift',
		// 	render: (text, item) => (
		// 		<CustomSelect
		// 			style={{width: 120}}
		// 			placeholder='선택'
		// 			// 기념품이 하나라면 placeholder 지우고 밑의 두줄 주석 풀기
		// 			// value={text}
		// 			// defaultValue='하프코스'
		// 			onChange={(value) => {
		// 				onChangeValue(item.key, value, 'gift');
		// 			}}
		// 			defaultValue={text === '' ? null : text}
		// 			disabled={isUpdateGift}
		// 			options={getGiftOpions(Number(item?.key ?? 0))}
		// 		></CustomSelect>
		// 	),
		// },
		{
			title: '삭제',
			dataIndex: '',
			key: 'x',
			width: '9%',
			render: (value, item) => {
				if (item?.isDeleted) {
					return <StyledText2>신청취소상태</StyledText2>;
				}
				if (item?.isDeposit) {
					return <StyledText2>입금완료</StyledText2>;
				}
				return (
					<StylesButton
						type='primary'
						onClick={() => deleteGroupItem(item.key)}
					>
						삭제
					</StylesButton>
				);
			},
		},
	];
	return (
		<>
			<Header justify='space-between'>
				<StyledText>
					총인원수 : <b>{newGroup?.length}</b> 명
				</StyledText>
				<Row style={{marginTop: 33}}>
					<Space size='large'>
						<StyledButton2 onClick={onClickAllItem}>
							전체선택
						</StyledButton2>
						<StyledButton2 onClick={addGroupItem}>
							인원추가
						</StyledButton2>
						<StyledButton2 onClick={selectDelete}>
							선택삭제
						</StyledButton2>
					</Space>
				</Row>
			</Header>
			<Table
				scroll={{x: 1170, y: 650}}
				rowSelection={{
					...select,
					getCheckboxProps,
					onChange: onChangeSelction,
					type: 'checkbox',
				}}
				columns={columns}
				dataSource={newGroup}
				pagination={false}
			/>
		</>
	);
}

const Header = styled(Row)({
	marginBottom: 20,
});

const StylesButton = styled(Button)({
	background: colorPalette.primary,
	'&&:hover': {
		background: colorPalette.primary,
	},
});

const StyledText = styled.p({
	fontSize: '1.125rem',
});

const StyledButton2 = styled(Button)({
	border: '1px solid #414c80',
	color: colorPalette.primary,
	fontSize: '1.125rem',
	height: '2.5rem',
	borderRadius: '2rem',
	padding: '0 1.5rem',
	'@media screen and (max-width: 450px)': {
		padding: '0 1rem',
	},
	'&&:hover': {
		border: '1px solid #414c80',
		color: colorPalette.primary,
	},
});

const StyledText2 = styled.p({});

const CustomSelect = styled(Select)({});
