import React from 'react';
import * as S from './style';
import {MdClear} from 'react-icons/md';
import {Text} from '../../Atom';
import {colorPalette, fontSize, fontWeight} from '../../../config';
const ModalUp = ({
	onClose,
	bottomClick,
	isClose = false,
	children,
	bottomText,
	imgs,
}) => {
	const textProps = {
		children: bottomText ? bottomText : '오늘 하루동안 보지않기',
		fontSize: {
			desktop: fontSize.lg,
			tablet: fontSize.base,
			mobile: fontSize.sm2,
		},
		color: colorPalette.black,
		fontWeight: fontWeight.medium,
	};

	if (isClose) {
		return;
	}
	return (
		<S.Container>
			<S.ModalUp>
				<S.TopWrapper onClick={onClose}>
					<MdClear fontSize='2rem' />
				</S.TopWrapper>
				{imgs && imgs.length > 0 ? (
					imgs[1].src === '' ? (
						imgs.map((img, index) => (
							<S.ModalImg {...img} />
						))
					) : (
						imgs.map((img, index) => (
							<S.ModalImgUp {...img} />
						))
					)
				) : children}
				<S.BottomWrapper onClick={bottomClick}>
					<Text {...textProps} />
				</S.BottomWrapper>
			</S.ModalUp>
		</S.Container>
	);
};

export default ModalUp;
