import {onlyLetter, onlyNumber} from '../../../util/validator';
import {
	inputTextProps,
	formButtonProps,
	inputEnglishTextProps,
	warnText,
	ulsContentProps,
} from '../../common';
import {makeDay, makeMonth, makeParticipated, makeYear} from '../../../util';
import {fontWeight, defaultSize} from '../../../config';

export const firstProps = {
	uls: {
		listStyle: 'circle',
		items: [
			{
				content: {
					...ulsContentProps,
					children: [
						'자원봉사자는 사전에 전자메일이나 문자로 송달받은 임무 내역을 숙지하시기 바랍니다.',
					],
				},
			},
			{
				content: {
					...ulsContentProps,
					children: [
						'행사 시작과 종료시 인원체크가 되어야 하며 그렇지 않을경우 자원봉사 인정을 하지 않습니다. (봉사기간:행사 전일 4시간, 행사 당일 8시간)',
					],
				},
			},
			{
				content: {
					...ulsContentProps,
					children: [
						'사무국의 지시를 잘 이행해야 하며 그렇지 않을 경우 봉사활동을 정지시키고 귀가 조치할 수 있습니다.',
					],
				},
			},
			{
				content: {
					...ulsContentProps,
					children: [
						'기타 사항은 마라톤참가자 일반 유의사항과 같습니다.',
					],
				},
			},
			{
				content: {
					...ulsContentProps,
					children: ['* 은 필수 입력입니다.'],
				},
			},
		],
	},
	inputs: [
		{
			type: 'radio',
			multiple: true,
			name: 'participated',
			text: {
				...inputTextProps,
				children: '* 봉사날짜',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Date',
			},
			items: makeParticipated(),
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'name',
			text: {
				...inputTextProps,
				children: '* 이름',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Name',
			},
			input: {
				...inputTextProps,
				name: 'name',
				pattern: onlyLetter,
				placeholder: '이름을 입력해주세요.',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'select',
			name: 'birth',
			text: {
				...inputTextProps,
				children: '* 생년월일',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Date of Birth',
			},
			selects: [
				{
					...inputTextProps,
					name: 'year',
					placeholder: '년도 (Year)',
					options: makeYear(),
					fontWeight: fontWeight.base,
				},
				{
					...inputTextProps,
					name: 'month',
					placeholder: '월 (Month)',
					options: makeMonth(),
					fontWeight: fontWeight.base,
				},
				{
					...inputTextProps,
					name: 'day',
					placeholder: '일 (Day)',
					options: makeDay(),
					fontWeight: fontWeight.base,
				},
			],
			warnText: {
				...warnText,
			},
		},
		{
			type: 'phone',

			name: 'phone',
			text: {
				...inputTextProps,
				children: '* 연락처',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Contact',
			},
			sep: true,
			inputs: [
				{
					...inputTextProps,
					name: 'phone1',
					pattern: onlyNumber,
					maxlength: 3,
					placeholder: '010',
				},
				{
					...inputTextProps,
					name: 'phone2',
					pattern: onlyNumber,
					maxlength: 4,
					placeholder: '1111',
				},
				{
					...inputTextProps,
					name: 'phone3',
					pattern: onlyNumber,
					maxlength: 4,
					placeholder: '2222',
				},
			],
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'school',
			text: {
				...inputTextProps,
				children: '학교명',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Name of school',
			},
			input: {
				...inputTextProps,
				name: 'school',
				placeholder: '학교명을 입력해주세요.',
			},
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'class_name',
			text: {
				...inputTextProps,
				children: '학년반',
			},
			englishText: {
				...inputEnglishTextProps,
				children: 'Grade and class',
			},
			sep: true,
			inputs: [
				{
					...inputTextProps,
					name: 'grade',
					pattern: onlyNumber,
					sep: {
						children: '학년',
						placeholder: '학년을 입력해주세요.',
					},
				},
				{
					...inputTextProps,
					name: 'class',
					pattern: onlyNumber,
					sep: {
						children: '반',
						placeholder: '반을 입력해주세요.',
					},
				},
			],
			warnText: {
				...warnText,
			},
		},
		{
			type: 'input',
			name: 'volunteer_id',
			text: {
				...inputTextProps,
				children: '1365 ID',
			},
			englishText: {
				...inputEnglishTextProps,
				children: '',
			},
			input: {
				...inputTextProps,
				name: 'volunteer_id',
				placeholder: '1365ID를 입력해주세요.',
			},
			warnText: {
				...warnText,
			},
		},
	],
	button: {
		text: {
			...formButtonProps,
			children: '신청하기',
		},
	},
};

export const initialInfo = {
	participated: [],
	name: '',
	phone1: '010',
	phone2: '',
	phone3: '',
	year: '',
	month: '',
	day: '',
	school: '',
	grade: '',
	class: '',
	volunteer_id: '',
};

export const invalidProps = {
	participated: '',
	name: '',
	phone: '',
	birth: '',
	school: '',
	depart: '',
	volunteer_id: '',
};
