import React, {useEffect, useState} from 'react';
import {Link, Button, Text} from '../../Atom';
import {
	ButtonWrapper,
	Container,
	LogoWrapper,
	MenuWrapper,
	NavWrapper,
	SubMenu,
	SubMenuContainer,
	SubMenuWrapper,
	Menu,
	TitleWrapper,
	IconWrapper,
	MobileContainer,
	MobileNavWrapper,
	MobileMenu,
	Fake,
	AdminNavContainer,
	AdminNavItem,
	AdminNavLeftBox,
	AdminNavRightBox,
	HomepageButton,
} from './style';
import {Drawer, Menu as AntdMenu, Space} from 'antd';
import {MdOutlineClose, MdOutlineMenu} from 'react-icons/md';
import {colorPalette, fontWeight} from '../../../config';
import {verityVerification} from '../../../api/admin';
import {adminMenuLeftList, adminMenuRightList} from './values';
import styled from 'styled-components';
const Nav = ({links, logo, button, menus = []}) => {
	const [open, setOpen] = useState(false);

	const [hidden, setHidden] = useState(true);
	const [mobileHidden, setMobileHidden] = useState(true);
	const [firstPath, setFirstPath] = useState('');
	const [secondPath, setSecondPath] = useState('');

	const getIsActiveMenuItem = (path) => {
		return window.location.pathname === path;
	};

	const generateMenu = (menus = []) => {
		return menus.map((menu, index, array) => {
			let paths = menu.href?.split('/');
			let lastPath;
			if (paths) {
				lastPath = paths[paths.length - 1];
			}
			return (
				<Menu key={index} isCurrent={lastPath === secondPath}>
					<Link {...menu}>
						<MobileSubtitleText
							isActiveMenuItem={getIsActiveMenuItem(menu.href)}
						>
							{menu.name}
						</MobileSubtitleText>
					</Link>
				</Menu>
			);
		});
	};

	useEffect(() => {
		let paths = window.location.pathname.split('/');
		setFirstPath(paths[1]);
		setSecondPath(paths[2]);
	}, []);

	useEffect(() => {
		(async () => {
			if (window.location.pathname.includes('admin')) {
				if (window.location.pathname.includes('login')) {
				} else {
					let res = await verityVerification();
					if (!res.data.verify) {
						alert(
							'로그인 정보가 만료되었습니다. 다시 로그인해주세요.',
						);
						sessionStorage.removeItem('verification');
						window.location.href =
							window.location.origin + '/admin/login';
					}
				}
			}
		})();
	});

	const onClose = () => {
		setOpen(false);
	};
	if (window.location.pathname.includes('admin')) {
		if (window.location.pathname.includes('login')) {
			return (
				<AntdMenu mode='horizontal'>
					<AntdMenu.Item>
						<a href={'/'}>홈페이지로</a>
					</AntdMenu.Item>
				</AntdMenu>
			);
		}

		return (
			<AdminNavContainer mode='horizontal'>
				<AdminNavLeftBox>
					{adminMenuLeftList.map((item) => {
						return (
							<AdminNavItem
								key={item.name}
								isActive={window.location.pathname.includes(
									item.path,
								)}
							>
								<a href={item.path}>{item.name}</a>
							</AdminNavItem>
						);
					})}
				</AdminNavLeftBox>
				<AdminNavRightBox>
					{adminMenuRightList.map((item) => {
						if (item.id === 'logout') {
							return (
								<AdminNavItem
									isActive={window.location.pathname.includes(
										item.path,
									)}
									key={item.name}
									onClick={() => {
										sessionStorage.removeItem(
											'verification',
										);
										window.location.href =
											window.location.origin +
											'/admin/login';
									}}
								>
									{item.name}
								</AdminNavItem>
							);
						}
						if (item.id === 'homepage') {
							return (
								<HomepageButton
									onClick={() => {
										window.open('/', '_blank');
										// window.location.href =
										// 	window.location.origin + '/';
									}}
								>
									{item.name}
								</HomepageButton>
							);
						}
						return (
							<AdminNavItem
								key={item.name}
								isActive={window.location.pathname.includes(
									item.path,
								)}
							>
								<a href={item.path}>{item.name}</a>
							</AdminNavItem>
						);
					})}
				</AdminNavRightBox>
			</AdminNavContainer>
		);
	}

	return (
		<React.Fragment>
			<Container onMouseLeave={() => setHidden(true)} isBoxShadow={!open}>
				<NavWrapper onMouseEnter={() => setHidden(false)}>
					<LogoWrapper
						src={logo}
						alt='로고 이미지'
						onClick={() =>
							(window.location.href = window.location.origin)
						}
					/>
					<MenuWrapper>
						{links.map((link, index, array) => {
							return (
								<Link
									key={index}
									{...link}
									color={
										link.key === firstPath
											? colorPalette.secondary
											: colorPalette.navColor
									}
								>
									{link.name}
								</Link>
							);
						})}
					</MenuWrapper>
					<ButtonWrapper>
						<Button {...button} />
					</ButtonWrapper>
					<IconWrapper>
						{mobileHidden ? (
							<MdOutlineMenu
								size={30}
								onClick={() => {
									setOpen(true);
									setMobileHidden(false);
								}}
							/>
						) : (
							<MdOutlineClose
								size={30}
								onClick={() => {
									setOpen(false);

									setMobileHidden(true);
								}}
							/>
						)}
					</IconWrapper>
				</NavWrapper>
				<SubMenuContainer isHidden={hidden}>
					<SubMenuWrapper>
						{menus.map((menu, index, array) => {
							return (
								<SubMenu key={index}>
									<TitleWrapper>
										<Text>{menu.title}</Text>
									</TitleWrapper>
									{generateMenu(menu.menu)}
								</SubMenu>
							);
						})}
					</SubMenuWrapper>
				</SubMenuContainer>
				<Drawer
					placement='right'
					width={window.innerWidth}
					onClose={onClose}
					open={open}
				>
					<MobileNavWrapper>
						{menus.map((menu, index, array) => {
							return (
								<MobileMenu key={index}>
									<TitleWrapper>
										<MobileText>{menu.title}</MobileText>
									</TitleWrapper>
									{generateMenu(menu.menu)}
								</MobileMenu>
							);
						})}
					</MobileNavWrapper>
				</Drawer>
			</Container>
			<Fake />
		</React.Fragment>
	);
};

export default Nav;

const MobileText = styled.span({
	fontFamily: 'Gmarket !important',
	fontSize: 20,
	fontWeight: 700,
	color: '#464646',
});

const MobileSubtitleText = styled.span(({isActiveMenuItem}) => ({
	fontSize: 16,
	fontWeight: isActiveMenuItem
		? `${fontWeight.bold}`
		: `${fontWeight.medium}`,
	color: isActiveMenuItem ? `${colorPalette.primary}` : '#464646',
	fontFamily: 'Pretendard',
}));
