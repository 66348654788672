import {
	cardTitleProps,
	tdProps,
	thProps,
	tableDescriptionProps,
} from '../../common';
import {
	courseImg,
	courseImg2,
	courseImg3,
	courseImg4,
	heightmap,
} from '../../../config/images';

import {colorPalette} from '../../../config';

// export const imageProps = {
// 	src: courseImg,
// 	alt: '코스',
// 	borderRadius: '1.375rem',
// 	border: `1px solid ${colorPalette.border}`,
// };
// export const imagePropsTitle = {
// 	title: {
// 		text: {children: '대회코스', ...cardTitleProps},
// 	},
// };
export const imageProps = {
	title0: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {children: '고저도', ...cardTitleProps, type: 'p'},
	},
	course0: {
		src: heightmap,
		alt: '고저도',
		borderRadius: '1.375rem',
	},
	title: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {children: '풀코스', ...cardTitleProps, type: 'p'},
	},
	course: {
		src: courseImg,
		alt: '풀코스',
		borderRadius: '1.375rem',
	},
	title2: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {children: '하프코스', ...cardTitleProps, type: 'p'},
	},
	course2: {
		src: courseImg2,
		alt: '하프코스',
		borderRadius: '1.375rem',
	},
	title3: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {children: '10km', ...cardTitleProps, type: 'p'},
	},
	course3: {
		src: courseImg3,
		alt: '10km',
		borderRadius: '1.375rem',
	},
	title4: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {children: '5km', ...cardTitleProps, type: 'p'},
	},
	course4: {
		src: courseImg4,
		alt: '5km',
		borderRadius: '1.375rem',
	},
};

export const limitTime = {
	title: {
		text: {children: '코스별 제한시간', ...cardTitleProps},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '출발시간'},
			{...thProps, children: '경기종료'},
			{...thProps, children: '제한시간'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '09:30'},
				{...tdProps, children: '14:30'},
				{...tdProps, children: '5시간'},
			],
			[
				{...tdProps, children: '하프코스'},
				{...tdProps, children: '09:40'},
				{...tdProps, children: '12:40'},
				{...tdProps, children: '3시간'},
			],
			[
				{...tdProps, children: '10km'},
				{...tdProps, children: '09:50'},
				{...tdProps, children: '11:50'},
				{...tdProps, children: '2시간'},
			],
			[
				{...tdProps, children: '5km'},
				{...tdProps, children: '09:55'},
				{...tdProps, children: '10:55'},
				{...tdProps, children: '1시간'},
			],
		],
		descriptions: [
			{
				content: {
					...tableDescriptionProps,
					children: `제한 시간이 넘었음에도 불구하고 주최 측 회송차량 승차를 거부한 채 계속 주행하던 도중에 발생하는 사고에 대한 모든 책임은 참가자 본인에게 있으며, 주최 측은 책임을 지지 않습니다.`,
				},
			},
		],
	},
};
